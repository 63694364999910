<template>
    <div>
        <div v-if="spaceUsers.length > 0 && fetchingSpaceUsers === false">
            <div class="d-flex flex-wrap align-center">
                <v-checkbox class="mx-4" v-model="showSpaceAdmins">
                    <template v-slot:label>
                        <div>
                            <v-icon class="mr-1" small color="primary">security</v-icon>
                            <span class="primary--text caption text-uppercase">administrators</span>
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox class="mx-4" v-model="showInstanceEditors">
                    <template v-slot:label>
                        <div>
                            <v-icon class="mr-1" small color="primary">edit</v-icon>
                            <span class="primary--text caption text-uppercase">editors</span>
                        </div>
                    </template></v-checkbox
                >
                <v-checkbox class="mx-4" v-model="showInstanceViewers">
                    <template v-slot:label>
                        <div>
                            <v-icon class="mr-1" small color="primary">visibility</v-icon>
                            <span class="primary--text caption text-uppercase">viewers</span>
                        </div>
                    </template>
                </v-checkbox>
                <v-spacer />
                <v-menu v-model="memberMenu" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-btn outlined v-on="on" text color="primary" class="flex-shrink-1">
                            <div class="d-flex align-center">
                                <v-icon small>search</v-icon>
                                <div class="ml-1 primary--text font-weight-bold caption text-truncate flex-shrink-1">{{ selectedMember }}</div>
                            </div>
                        </v-btn>
                    </template>
                    <v-list style="max-height:500px" class="overflow-y-auto" nav dense>
                        <v-list-item>
                            <v-text-field
                                v-model="memberSearch"
                                autofocus
                                class="ma-0 pa-0"
                                append-icon="search"
                                label="Filter"
                                dense
                                single-line
                            ></v-text-field>
                        </v-list-item>
                        <v-list-item v-for="item in memberEmails" :key="item" @click="selectedMember = item">
                            <v-list-item-title>
                                {{ item }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="ml-1" v-on="on" @click="$store.dispatch('spaceStore/fetchSpaceUsers', $route.params.sid)" text icon
                            ><v-icon>refresh</v-icon></v-btn
                        >
                    </template>
                    <span>refresh</span>
                </v-tooltip>
            </div>
            <v-divider class="mb-5"></v-divider>
            <v-data-iterator :items="filteredUsersByRole">
                <template v-slot:default="props">
                    <div v-for="(item, index) in props.items" :key="item.uid">
                        <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                        <v-list-item two-line>
                            <v-row align="center">
                                <v-col cols="1">
                                    <v-list-item-avatar>
                                        <v-icon x-large>person</v-icon>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.full_name }} <v-chip x-small v-if="item.is_active === true" color="success">Active</v-chip>
                                            <v-chip x-small v-else-if="item.is_active === false" color="error">Inactive</v-chip>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span class="caption mr-3 font-weight-bold">{{ item.email }}</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item-content>
                                        <v-list-item-subtitle style="width:30%">
                                            <ul style="list-style-type: none;">
                                                <ComponentWithStyledBorder v-if="item.space_role === roleTypes.SPACE_ADMIN && showSpaceAdmins === true">
                                                    <li>
                                                        <div class="d-flex justify-space-between align-center">
                                                            <div class="d-flex align-center">
                                                                <v-icon class="mr-1 ml-1">security</v-icon>
                                                                {{ item.space_name }}
                                                            </div>
                                                            <UserActions
                                                                :uid="item.uid.toString()"
                                                                :ucid="item.ucid.toString()"
                                                                :isActiveUser="item.is_active"
                                                                :userName="item.full_name"
                                                                hierarchicalLevel="space"
                                                                :showRevokeButton="true"
                                                                :showDeactivateActivateButton="false"
                                                                role="Space Administrator"
                                                                :spaceName="item.space_name"
                                                            ></UserActions>
                                                        </div>
                                                    </li>
                                                </ComponentWithStyledBorder>
                                                <div v-if="item.instance_editor_roles.length && showInstanceEditors === true">
                                                    <ComponentWithStyledBorder v-for="role in item.instance_editor_roles" :key="role.iid">
                                                        <li>
                                                            <div class="d-flex justify-space-between align-center">
                                                                <div class="d-flex align-center">
                                                                    <v-icon v-if="role.role_name === roleTypes.INSTANCE_VIEWER" class="mr-1 ml-1"
                                                                        >visibility</v-icon
                                                                    >
                                                                    <v-icon v-else-if="role.role_name === roleTypes.INSTANCE_EDITOR" class="mr-1 ml-1"
                                                                        >edit</v-icon
                                                                    >
                                                                    {{ role.Instance }}
                                                                </div>
                                                                <UserActions
                                                                    :uid="item.uid.toString()"
                                                                    :ucid="item.ucid.toString()"
                                                                    :isActiveUser="item.is_active"
                                                                    :userName="item.full_name"
                                                                    hierarchicalLevel="instance"
                                                                    :spaceAdmin="false"
                                                                    :showRevokeButton="true"
                                                                    :showDeactivateActivateButton="false"
                                                                    :instanceId="role.iid.toString()"
                                                                    :instanceName="role.Instance"
                                                                    :role="role.role_name"
                                                                ></UserActions>
                                                            </div>
                                                        </li>
                                                    </ComponentWithStyledBorder>
                                                </div>
                                                <div v-if="item.instance_viewer_roles.length && showInstanceViewers === true">
                                                    <ComponentWithStyledBorder v-for="role in item.instance_viewer_roles" :key="role.iid">
                                                        <li>
                                                            <div class="d-flex justify-space-between align-center">
                                                                <div class="d-flex align-center">
                                                                    <v-icon v-if="role.role_name === roleTypes.INSTANCE_VIEWER" class="mr-1 ml-1"
                                                                        >visibility</v-icon
                                                                    >
                                                                    <v-icon v-else-if="role.role_name === roleTypes.INSTANCE_EDITOR" class="mr-1 ml-1"
                                                                        >edit</v-icon
                                                                    >
                                                                    {{ role.Instance }}
                                                                </div>
                                                                <UserActions
                                                                    :uid="item.uid.toString()"
                                                                    :ucid="item.ucid.toString()"
                                                                    :isActiveUser="item.is_active"
                                                                    :userName="item.full_name"
                                                                    hierarchicalLevel="instance"
                                                                    :spaceAdmin="false"
                                                                    :showRevokeButton="true"
                                                                    :showDeactivateActivateButton="false"
                                                                    :instanceId="role.iid.toString()"
                                                                    :instanceName="role.Instance"
                                                                    :role="role.role_name"
                                                                ></UserActions>
                                                            </div>
                                                        </li>
                                                    </ComponentWithStyledBorder>
                                                </div>
                                                <v-hover v-slot:default="{ hover }">
                                                    <div :style="hover ? 'background-color: rgb(96, 96, 96, 0.1);cursor: pointer;' : ''" class="mt-1">
                                                        <li>
                                                            <AssignRolesDialog
                                                                :assignee="item.full_name"
                                                                :instanceData="getTargetInstancesForUserAssign(item.uid)"
                                                                :spaceName="item.space_name"
                                                                :ucid="item.ucid.toString()"
                                                                :spaceRole="item.space_role"
                                                            >
                                                            </AssignRolesDialog>
                                                        </li>
                                                    </div>
                                                </v-hover>
                                            </ul>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                            </v-row>
                            <v-list-item-action>
                                <div class="d-flex align-center">
                                    <UserActions
                                        v-if="item.space_role !== roleTypes.SPACE_ADMIN"
                                        :uid="item.uid.toString()"
                                        :ucid="item.ucid.toString()"
                                        :isActiveUser="item.is_active"
                                        :userName="item.full_name"
                                        hierarchicalLevel="instance"
                                        :spaceAdmin="false"
                                        :showRevokeButton="false"
                                    ></UserActions>
                                    <UserActions
                                        v-else-if="item.space_role === roleTypes.SPACE_ADMIN"
                                        :uid="item.uid.toString()"
                                        :ucid="item.ucid.toString()"
                                        :isActiveUser="item.is_active"
                                        :userName="item.full_name"
                                        hierarchicalLevel="space"
                                        :showRevokeButton="false"
                                    ></UserActions>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </div>
                    <div>
                        <v-list-item @click="$emit('goToInvite', { value: true })" two-line>
                            <v-row align="center">
                                <v-col cols="1">
                                    <v-list-item-avatar>
                                        <v-icon x-large>add</v-icon>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item-content>
                                        <v-list-item-title>Invite</v-list-item-title>
                                    </v-list-item-content>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </div>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning" text class="mt-3">Your search for found no results.</v-alert>
                </template>
            </v-data-iterator>
        </div>
        <div v-else-if="spaceUsers.length === 0 && fetchingSpaceUsers === false">
            <v-banner single-line>
                <v-avatar slot="icon" color="white" size="60">
                    <v-icon x-large icon="info" color="info">
                        info
                    </v-icon>
                </v-avatar>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold primary--text">No users found</span>
                    <p>
                        Currently this space has no users. Consider inviting new users via the
                        <span class="text-uppercase font-weight-bold secondary--text"><v-icon small color="secondary">add</v-icon>invite</span> tab above.
                    </p>
                </div>
            </v-banner>
        </div>
        <div v-else-if="fetchingSpaceUsers === true">
            <div
                v-for="item in [
                    { id: 'c1', opacity: 1 },
                    { id: 'c2', opacity: 0.75 },
                    { id: 'c3', opacity: 0.5 }
                ]"
                :key="item.id"
                class="my-6"
            >
                <div :style="{ opacity: item.opacity }">
                    <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
const UserActions = () => import('@/components/UserActions')
const AssignRolesDialog = () => import('@/modules/space/components/TheSpaceAssignRolesDialog.vue')
const ComponentWithStyledBorder = () => import('./SpaceManagementComponentWithStyledBorder')
export default {
    mixins: [enumsData],
    components: { UserActions, AssignRolesDialog, ComponentWithStyledBorder },
    data() {
        return {
            search: '',
            selectedMember: 'all members',
            memberSearch: '',
            showSpaceAdmins: true,
            showInstanceEditors: true,
            showInstanceViewers: true,
            memberMenu: false
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceUsers', 'spaceInstances', 'fetchingSpaceUsers']),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        filteredUsersByRole() {
            return this.filteredSpaceUsers.filter(user => {
                if (this.showSpaceAdmins && this.showInstanceEditors && this.showInstanceViewers) {
                    return user
                } else if (
                    (this.showSpaceAdmins === true && user.space_role === this.roleTypes.SPACE_ADMIN) ||
                    (this.showInstanceEditors === true && user.instance_editor_roles && user.instance_editor_roles.length) ||
                    (this.showInstanceViewers === true && user.instance_viewer_roles && user.instance_viewer_roles.length)
                ) {
                    return user
                }
            })
        },
        filteredSpaceUsers() {
            if (this.spaceUsers && this.$data.selectedMember === 'all members') {
                return this.spaceUsers
            } else if (this.spaceUsers) {
                return this.spaceUsers.filter(user => user.email === this.$data.selectedMember)
            }
            return []
        },
        memberEmails() {
            if (this.spaceUsers && !this.$data.memberSearch.length) {
                const userEmails = this.spaceUsers.map(user => user.email)
                return ['all members'].concat(userEmails)
            } else if (this.spaceUsers) {
                const filteredUserList = this.spaceUsers.filter(user => user.email.toLowerCase().includes(this.$data.memberSearch.toLowerCase()))
                const emailList = filteredUserList.map(user => user.email)
                return emailList
            }
            return []
        }
    },
    methods: {
        getTargetInstancesForUserAssign: function(uid) {
            const spaceUserData = this.spaceUsers.filter(user => user.uid === uid)
            const userAssignedInstances = spaceUserData[0].instance_editor_roles
                .concat(spaceUserData[0].instance_viewer_roles)
                .map(instance => instance.Instance)
            const userNonAssignedInstances = this.spaceInstances
                .filter(instance => !userAssignedInstances.includes(instance.long_id))
                .map(instance => {
                    return { iid: instance.iid, instance_name: instance.long_id, selectedRole: 0, selectedInstance: null }
                })
            return userNonAssignedInstances
        }
    },
    mounted() {
        this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
    },
    watch: {
        memberMenu: function(nextVal) {
            if (nextVal && this.$data.memberSearch.length) {
                this.$data.memberSearch = ''
            }
        },
        memberEmails: function(nextVal) {
            if (nextVal && nextVal.length && this.$data.selectedMember !== 'all members' && nextVal.includes(this.$data.selectedMember) === false) {
                this.$data.selectedMember = 'all members'
            }
        }
    }
}
</script>
