var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.spaceUsers.length > 0 && _vm.fetchingSpaceUsers === false
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-center" },
              [
                _c("v-checkbox", {
                  staticClass: "mx-4",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "primary" }
                                  },
                                  [_vm._v("security")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "primary--text caption text-uppercase"
                                  },
                                  [_vm._v("administrators")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2579418372
                  ),
                  model: {
                    value: _vm.showSpaceAdmins,
                    callback: function($$v) {
                      _vm.showSpaceAdmins = $$v
                    },
                    expression: "showSpaceAdmins"
                  }
                }),
                _c("v-checkbox", {
                  staticClass: "mx-4",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "primary" }
                                  },
                                  [_vm._v("edit")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "primary--text caption text-uppercase"
                                  },
                                  [_vm._v("editors")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1984619348
                  ),
                  model: {
                    value: _vm.showInstanceEditors,
                    callback: function($$v) {
                      _vm.showInstanceEditors = $$v
                    },
                    expression: "showInstanceEditors"
                  }
                }),
                _c("v-checkbox", {
                  staticClass: "mx-4",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "primary" }
                                  },
                                  [_vm._v("visibility")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "primary--text caption text-uppercase"
                                  },
                                  [_vm._v("viewers")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1834914581
                  ),
                  model: {
                    value: _vm.showInstanceViewers,
                    callback: function($$v) {
                      _vm.showInstanceViewers = $$v
                    },
                    expression: "showInstanceViewers"
                  }
                }),
                _c("v-spacer"),
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "", "close-on-content-click": false },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "flex-shrink-1",
                                    attrs: {
                                      outlined: "",
                                      text: "",
                                      color: "primary"
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("search")
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ml-1 primary--text font-weight-bold caption text-truncate flex-shrink-1"
                                        },
                                        [_vm._v(_vm._s(_vm.selectedMember))]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1774843547
                    ),
                    model: {
                      value: _vm.memberMenu,
                      callback: function($$v) {
                        _vm.memberMenu = $$v
                      },
                      expression: "memberMenu"
                    }
                  },
                  [
                    _c(
                      "v-list",
                      {
                        staticClass: "overflow-y-auto",
                        staticStyle: { "max-height": "500px" },
                        attrs: { nav: "", dense: "" }
                      },
                      [
                        _c(
                          "v-list-item",
                          [
                            _c("v-text-field", {
                              staticClass: "ma-0 pa-0",
                              attrs: {
                                autofocus: "",
                                "append-icon": "search",
                                label: "Filter",
                                dense: "",
                                "single-line": ""
                              },
                              model: {
                                value: _vm.memberSearch,
                                callback: function($$v) {
                                  _vm.memberSearch = $$v
                                },
                                expression: "memberSearch"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._l(_vm.memberEmails, function(item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item,
                              on: {
                                click: function($event) {
                                  _vm.selectedMember = item
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-1",
                                    attrs: { text: "", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$store.dispatch(
                                          "spaceStore/fetchSpaceUsers",
                                          _vm.$route.params.sid
                                        )
                                      }
                                    }
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("refresh")])],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      280669242
                    )
                  },
                  [_c("span", [_vm._v("refresh")])]
                )
              ],
              1
            ),
            _c("v-divider", { staticClass: "mb-5" }),
            _c("v-data-iterator", {
              attrs: { items: _vm.filteredUsersByRole },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _vm._l(props.items, function(item, index) {
                          return _c(
                            "div",
                            { key: item.uid },
                            [
                              index > 0
                                ? _c("v-divider", {
                                    staticClass: "mb-2",
                                    staticStyle: {
                                      opacity: "0.25",
                                      "border-width": "thin"
                                    },
                                    attrs: { inset: "" }
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { align: "center" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-list-item-avatar",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-large": "" } },
                                                [_vm._v("person")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.full_name) +
                                                      " "
                                                  ),
                                                  item.is_active === true
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "success"
                                                          }
                                                        },
                                                        [_vm._v("Active")]
                                                      )
                                                    : item.is_active === false
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "error"
                                                          }
                                                        },
                                                        [_vm._v("Inactive")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-subtitle", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption mr-3 font-weight-bold"
                                                  },
                                                  [_vm._v(_vm._s(item.email))]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticStyle: { width: "30%" }
                                                },
                                                [
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticStyle: {
                                                        "list-style-type":
                                                          "none"
                                                      }
                                                    },
                                                    [
                                                      item.space_role ===
                                                        _vm.roleTypes
                                                          .SPACE_ADMIN &&
                                                      _vm.showSpaceAdmins ===
                                                        true
                                                        ? _c(
                                                            "ComponentWithStyledBorder",
                                                            [
                                                              _c("li", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between align-center"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-center"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1 ml-1"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "security"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.space_name
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "UserActions",
                                                                      {
                                                                        attrs: {
                                                                          uid: item.uid.toString(),
                                                                          ucid: item.ucid.toString(),
                                                                          isActiveUser:
                                                                            item.is_active,
                                                                          userName:
                                                                            item.full_name,
                                                                          hierarchicalLevel:
                                                                            "space",
                                                                          showRevokeButton: true,
                                                                          showDeactivateActivateButton: false,
                                                                          role:
                                                                            "Space Administrator",
                                                                          spaceName:
                                                                            item.space_name
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.instance_editor_roles
                                                        .length &&
                                                      _vm.showInstanceEditors ===
                                                        true
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              item.instance_editor_roles,
                                                              function(role) {
                                                                return _c(
                                                                  "ComponentWithStyledBorder",
                                                                  {
                                                                    key:
                                                                      role.iid
                                                                  },
                                                                  [
                                                                    _c("li", [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-space-between align-center"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex align-center"
                                                                            },
                                                                            [
                                                                              role.role_name ===
                                                                              _vm
                                                                                .roleTypes
                                                                                .INSTANCE_VIEWER
                                                                                ? _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 ml-1"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "visibility"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : role.role_name ===
                                                                                  _vm
                                                                                    .roleTypes
                                                                                    .INSTANCE_EDITOR
                                                                                ? _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 ml-1"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "edit"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    role.Instance
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "UserActions",
                                                                            {
                                                                              attrs: {
                                                                                uid: item.uid.toString(),
                                                                                ucid: item.ucid.toString(),
                                                                                isActiveUser:
                                                                                  item.is_active,
                                                                                userName:
                                                                                  item.full_name,
                                                                                hierarchicalLevel:
                                                                                  "instance",
                                                                                spaceAdmin: false,
                                                                                showRevokeButton: true,
                                                                                showDeactivateActivateButton: false,
                                                                                instanceId: role.iid.toString(),
                                                                                instanceName:
                                                                                  role.Instance,
                                                                                role:
                                                                                  role.role_name
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      item.instance_viewer_roles
                                                        .length &&
                                                      _vm.showInstanceViewers ===
                                                        true
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              item.instance_viewer_roles,
                                                              function(role) {
                                                                return _c(
                                                                  "ComponentWithStyledBorder",
                                                                  {
                                                                    key:
                                                                      role.iid
                                                                  },
                                                                  [
                                                                    _c("li", [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-space-between align-center"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex align-center"
                                                                            },
                                                                            [
                                                                              role.role_name ===
                                                                              _vm
                                                                                .roleTypes
                                                                                .INSTANCE_VIEWER
                                                                                ? _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 ml-1"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "visibility"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : role.role_name ===
                                                                                  _vm
                                                                                    .roleTypes
                                                                                    .INSTANCE_EDITOR
                                                                                ? _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 ml-1"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "edit"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    role.Instance
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "UserActions",
                                                                            {
                                                                              attrs: {
                                                                                uid: item.uid.toString(),
                                                                                ucid: item.ucid.toString(),
                                                                                isActiveUser:
                                                                                  item.is_active,
                                                                                userName:
                                                                                  item.full_name,
                                                                                hierarchicalLevel:
                                                                                  "instance",
                                                                                spaceAdmin: false,
                                                                                showRevokeButton: true,
                                                                                showDeactivateActivateButton: false,
                                                                                instanceId: role.iid.toString(),
                                                                                instanceName:
                                                                                  role.Instance,
                                                                                role:
                                                                                  role.role_name
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c("v-hover", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var hover =
                                                                  ref.hover
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "mt-1",
                                                                      style: hover
                                                                        ? "background-color: rgb(96, 96, 96, 0.1);cursor: pointer;"
                                                                        : ""
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "li",
                                                                        [
                                                                          _c(
                                                                            "AssignRolesDialog",
                                                                            {
                                                                              attrs: {
                                                                                assignee:
                                                                                  item.full_name,
                                                                                instanceData: _vm.getTargetInstancesForUserAssign(
                                                                                  item.uid
                                                                                ),
                                                                                spaceName:
                                                                                  item.space_name,
                                                                                ucid: item.ucid.toString(),
                                                                                spaceRole:
                                                                                  item.space_role
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-action", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        item.space_role !==
                                        _vm.roleTypes.SPACE_ADMIN
                                          ? _c("UserActions", {
                                              attrs: {
                                                uid: item.uid.toString(),
                                                ucid: item.ucid.toString(),
                                                isActiveUser: item.is_active,
                                                userName: item.full_name,
                                                hierarchicalLevel: "instance",
                                                spaceAdmin: false,
                                                showRevokeButton: false
                                              }
                                            })
                                          : item.space_role ===
                                            _vm.roleTypes.SPACE_ADMIN
                                          ? _c("UserActions", {
                                              attrs: {
                                                uid: item.uid.toString(),
                                                ucid: item.ucid.toString(),
                                                isActiveUser: item.is_active,
                                                userName: item.full_name,
                                                hierarchicalLevel: "space",
                                                showRevokeButton: false
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _c(
                          "div",
                          [
                            _c(
                              "v-list-item",
                              {
                                attrs: { "two-line": "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("goToInvite", {
                                      value: true
                                    })
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center" } },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "1" } },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-large": "" } },
                                              [_vm._v("add")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v("Invite")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "no-results",
                    fn: function() {
                      return [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mt-3",
                            attrs: {
                              value: true,
                              color: "error",
                              icon: "warning",
                              text: ""
                            }
                          },
                          [_vm._v("Your search for found no results.")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                200076278
              )
            })
          ],
          1
        )
      : _vm.spaceUsers.length === 0 && _vm.fetchingSpaceUsers === false
      ? _c(
          "div",
          [
            _c(
              "v-banner",
              { attrs: { "single-line": "" } },
              [
                _c(
                  "v-avatar",
                  {
                    attrs: { slot: "icon", color: "white", size: "60" },
                    slot: "icon"
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { "x-large": "", icon: "info", color: "info" } },
                      [_vm._v(" info ")]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c(
                    "span",
                    { staticClass: "font-weight-bold primary--text" },
                    [_vm._v("No users found")]
                  ),
                  _c("p", [
                    _vm._v(
                      " Currently this space has no users. Consider inviting new users via the "
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-uppercase font-weight-bold secondary--text"
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { small: "", color: "secondary" } },
                          [_vm._v("add")]
                        ),
                        _vm._v("invite")
                      ],
                      1
                    ),
                    _vm._v(" tab above. ")
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      : _vm.fetchingSpaceUsers === true
      ? _c(
          "div",
          _vm._l(
            [
              { id: "c1", opacity: 1 },
              { id: "c2", opacity: 0.75 },
              { id: "c3", opacity: 0.5 }
            ],
            function(item) {
              return _c("div", { key: item.id, staticClass: "my-6" }, [
                _c(
                  "div",
                  { style: { opacity: item.opacity } },
                  [
                    _c("v-skeleton-loader", {
                      staticClass: "mx-auto",
                      attrs: { type: "list-item-avatar-two-line" }
                    })
                  ],
                  1
                )
              ])
            }
          ),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }